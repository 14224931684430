.container {
    width: 100%;
}
.title {
    display: flex;
    justify-content: center;
    color: #63d1a2;
    font-weight: 300;
    font-size: 1.5rem;
}
.row{
    display: flex;
    justify-content: space-between;
}
.column {
    width: 45%;
}

.inputBig{
    width: 30%
}

.inputSm{
    width: 15%
}

.buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.buttonRow{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.button{
    width: 10rem;
    padding: 6px 16px!important;
    height: 40px!important;
    color: #ffffff!important;
    background: linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%)!important;
    text-transform: capitalize!important;
    border-radius: 2rem!important;
    font-weight: 300!important;
    margin: 0 1rem 1rem!important;
}

.button:hover{
    background: linear-gradient(90deg, #13d5a5 0%, #13c5d5 100%)!important;
}

.buttonCoordenadas{
    position: absolute!important;
    width: 14rem;
    height: 2rem;
    color: #ffffff!important;
    background: linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%)!important;
    border-radius: 21px!important;
    text-transform: capitalize!important;
    font-weight: 300!important;
    bottom: 1.5rem;
    left: 1.5rem;  
}

.closeButton{
    background: #bbb!important;
}

.closeButton:hover{
    background: #777!important;
}

.mapa {
    flex-grow: 1;
    padding-right: 4rem;
}

.mapaModal {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column
}

.searchBoxContainer {
    margin-bottom: 1rem;
    padding-right: 2rem;
}

.searchBox {
    border: 1px solid #000;
    width: 100%;
    height: 32px;
    padding: 0 12px;
    border-radius: 3px;
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
    z-index: 100;
    color: #000;
}
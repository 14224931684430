.container {
    width: 100%;
    max-height: 85vh;
    overflow-y: auto;
}
.title {
    display: flex;
    justify-content: center;
    color: #63d1a2;
    font-weight: lighter;
    font-size: 1.5rem;
    font-family: var(--font-g);
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    justify-content: space-between;
    text-align: left;
    padding-left: 20px;
}

.cell {
    padding: 8px;
}

.subtitle {
    font-weight: bold;
}

.info {
    font-weight: 400;
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    width: 100%;
}

.image {
    max-width: 400px;
}

.closeButtonContainer {
    display: flex;
    justify-content: center;
}

.button {
    color: #ffffff!important;
    width: 16rem!important;
    height: 2rem!important;
    background: linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%)!important;
    border-radius: 21px!important;
    opacity: 1!important;
    text-transform: none!important;
    font-weight: 300!important;
    font-family: 'Roboto', sans-serif!important;
    margin-top: 1em!important;
    border: none;
}
main {
    height: calc(100vh - 150px);
    padding: 0 0.5em
}
  
.row-grid {
    width: 100%;
    height: 80%;
    margin-bottom: 1%;
    position: relative;
}

.container-usuario {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 9.4rem;
    font-family: var(--font-g);
}

.container-pantallas-usuario{
    background-color: #F2F2F2;
    display: flex;
    width: 100%;
    height: 40px;
}

.select-pantalla-usuario{
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    text-align: center;
    color: #A7A7A7;
    height: 40px;
}

.select-pantalla-usuario.violeta{
    background-color: #9373B1;
    color: #FFFFFF;
    font-weight: bold;
}

.datos-personales{
    width: 100%;
    display: flex;
    padding: 1em 0;
    /* margin-top: */
}

.datos-personales-column{
    flex-grow: 1;
    color: #707070;
    max-width: 20%;
}

.datos-personales-column-big{
    flex-grow: 1;
    color: #707070;
    max-width: 40%;
}
.datos-personales-img-big{
    width: 400px;
    height: 250px;
    padding: 0 1em;
    object-fit: cover;
}

.datos-personales-img{
    width: 250px;
    height: 250px;
    padding: 0 1em;
    object-fit: cover;
}

.dato-personal-label{
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
}

.dato-personal{
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 14px;
}

.dato-personal-mid{
    margin: 0.5rem;
    font-size: 18px;
}

.dato-personal-big{
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 22px;
    color: #A7A7A7
}

.titulo-violeta{
    color: #9373B1;
    font-size: 22px;
    margin: 0;
    font-weight: bold;
}

.rectangulo-gris{
    border-radius: 10px;
    background-color:#F2F2F2;
    margin-bottom: 0.5rem;
    text-align: center;
    padding: 0.5rem;
}

.card-gris{
    background-color: #FAFAFA;
    padding: 1rem;
    width: 80%;
    margin: auto;
}

.card-title{
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin: 0;
    margin-bottom: 1.5rem;
}

.container-dato-biometrico{
    display: flex;
    justify-content: space-between;
}

.rectangulo-verde{
    background-color: #63D1A2;
    border-radius: 0px 0px 10px 10px;
    margin: auto;
    width: 80%;
    padding: 0 1em;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rectangulo-rojo{
    background-color: #D44141;
    border-radius: 0px 0px 10px 10px;
    margin: auto;
    width: 80%;
    padding: 0 1em;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rectangulo-gris-oscuro{
    background-color: #A7A7A7;
    border-radius: 0px 0px 10px 10px;
    margin: auto;
    width: 80%;
    padding: 0 1em;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid-datos-biometricos{
    height: 30vh;
    width: 100%
}

.metodos-de-pago{
    display: flex;
    flex-direction: column;
    margin-right: 1em;
    flex-grow: 2;
    max-width: 778px; 
}

.ultimos-movimientos{
    display: flex;
    flex-direction: column;
    flex-grow: 3
}

.grid-suscripciones{
    height: 30vh;
    width: 100%;
    max-width: 1160px;
}
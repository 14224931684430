@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);
.HeaderButton_buttons__39Lb4 {
  border-radius: 50%;
  border: none;
  cursor: pointer;
  margin: 0 0.25rem;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.HeaderButton_buttons__39Lb4:hover {
  box-shadow: 0px 1px 3px 1px rgb(255, 254, 254);
}

.HeaderButton_buttons__39Lb4:focus {
  outline: none;
}

.HeaderButton_icons-container__PfUox div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin: 0rem 0.3rem 0rem 0.3rem;
}

.HeaderButton_tooltip__2w5DD {
  position: relative;
  display: inline-block;
}

.HeaderButton_tooltip__2w5DD .HeaderButton_tiptext__2-DMJ {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  visibility: hidden;
  width: 6rem;
  height: 0.4rem;
  background-color: #707070;
  color: #ffffff;
  text-align: center;
  padding: 0.38rem 0;
  position: absolute;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  font-size: 0.6rem;
  font-family: var(--font-g);
  font-weight: 300;
}

.HeaderButton_tooltip__2w5DD .HeaderButton_tiptext__2-DMJ::after {
  content: "";
  position: absolute;
  border-width: 0.31rem;
  border-style: solid;
}

.HeaderButton_tooltip__2w5DD:hover .HeaderButton_tiptext__2-DMJ {
  visibility: visible;
}

.HeaderButton_tooltip__2w5DD.HeaderButton_bottom__3w986.HeaderButton_page__-I4YY{
    margin: 1em;
    margin-right: 0;
}

.HeaderButton_tooltip__2w5DD.HeaderButton_bottom__3w986 .HeaderButton_tiptext__2-DMJ {
  margin-left: -3.75rem;
  top: 120%;
}

.HeaderButton_tooltip__2w5DD.HeaderButton_bottom__3w986 .HeaderButton_tiptext__2-DMJ::after {
  margin-left: 1.5rem;
  bottom: 100%;
  left: 50%;
  border-color: transparent transparent #707070 transparent;
}

.HeaderButton_buttons__39Lb4 img {
  position: relative;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: HeaderButton_slidein__2yGFi;
          animation-name: HeaderButton_slidein__2yGFi;
  height: 40px;
  width: 40px;
}

@-webkit-keyframes HeaderButton_slidein__2yGFi {
  from {
    height: 0px;
    width: 0;
  }

  to {
    height: 2.6rem;
    width: 2.6rem;
  }
}

@keyframes HeaderButton_slidein__2yGFi {
  from {
    height: 0px;
    width: 0;
  }

  to {
    height: 2.6rem;
    width: 2.6rem;
  }
}

.dashboard_mainContainer__2p-AN {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: #ffffff;
}

.dashboard_figures__2pe9B {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 100%;
  height: auto;
  padding-top: 8rem;
}

.dashboard_figures__2pe9B .dashboard_elipse__k5yvE {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-right: 1rem;
}

.dashboard_elipse__k5yvE img {
  width: 7rem;
  padding-left: 1.5rem;
}

.dashboard_elipse__k5yvE .dashboard_rombo__21E10 {
  width: 0.7rem;
  padding-top: 1rem;
  margin-left: 3rem;
}

.dashboard_figures__2pe9B .dashboard_vertical-line__3l1C4 {
  padding-top: 6.4rem;
  padding-right: 13%;
  margin-left: -3.4rem;
}

.dashboard_vertical-line__3l1C4 .dashboard_line__rH2Wi {
  width: 3rem;
}

.dashboard_section__3DUOA {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: auto;
  -webkit-justify-content: center;
          justify-content: center;
  line-height: 0;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  width: 100%;
}

.dashboard_section__3DUOA h1 {
  color: #63C3D1;
  font-family: var(--font-g);
  font-weight: 300;
  font-size: 2rem;
}

.dashboard_section__3DUOA p {
  color: #707070;
  font-family: var(--font-g);
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  text-align: center;
  width: 30%;
}

.dashboard_figuresLeft__1pOGC {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: auto;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: 100%;
}

.dashboard_containerLeft__38adE {
  display: -webkit-flex;
  display: flex;
  padding-left: 13%;
  padding-top: 2%;
}

.dashboard_halfWheel__WVotU img {
  width: 7rem;
}

.dashboard_cross__10nbk {
  margin-left: -16%;
}

.dashboard_cross__10nbk img {
  width: 1.8rem;
}

.dashboard_rombo__21E10 {
  padding-left: 12%;
  padding-top: 19%;
}

.dashboard_rombo__21E10 img {
  width: 0.82rem;
}

@media (min-width: 1920px) {
  .dashboard_figures__2pe9B {
    top: 14rem;
  }

  .dashboard_elipse__k5yvE img {
    width: 10.81rem;
  }

  .dashboard_elipse__k5yvE .dashboard_rombo__21E10 {
    width: 1.51rem;
  }

  .dashboard_figures__2pe9B .dashboard_vertical-line__3l1C4 {
    padding-top: 10rem;
    margin-left: -4%;
    padding-right: 9%;
  }

  .dashboard_vertical-line__3l1C4 .dashboard_line__rH2Wi {
    width: 4.61rem;
  }

  .dashboard_section__3DUOA h1 {
    font-size: 2.5rem;
  }

  .dashboard_section__3DUOA p {
    font-weight: 300 lighter;
    font-size: 1.4rem;
    width: 35%;
  }

  .dashboard_half-wheel__Nj6l1 img {
    width: 10.83rem;
  }

  .dashboard_cross__10nbk img {
    width: 2.12rem;
  }

  .dashboard_rombo__21E10 {
    margin-top: 5%;
  }

  .dashboard_rombo__21E10 img {
    width: 1.09rem;
  }
  .dashboard_container-left__2Ao3O {
    padding-left: 9%;
  }

  .dashboard_cross__10nbk {
    margin-top: 5%;
    margin-left: -21%;
  }
}

@media (max-width: 1600px) {
  .dashboard_figures__2pe9B {
    margin-top: -10%;
  }
}

@media (max-width: 1280px) {
  .dashboard_figures__2pe9B {
    margin-top: -7%;
  }
}

main {
    height: calc(100vh - 150px);
    padding: 0 0.5em
}
  
.row-grid {
    width: 100%;
    height: 80%;
    margin-bottom: 1%;
    position: relative;
}

.container-usuario {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 9.4rem;
    font-family: var(--font-g);
}

.container-pantallas-usuario{
    background-color: #F2F2F2;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 40px;
}

.select-pantalla-usuario{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 18px;
    text-align: center;
    color: #A7A7A7;
    height: 40px;
}

.select-pantalla-usuario.violeta{
    background-color: #9373B1;
    color: #FFFFFF;
    font-weight: bold;
}

.datos-personales{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    padding: 1em 0;
    /* margin-top: */
}

.datos-personales-column{
    -webkit-flex-grow: 1;
            flex-grow: 1;
    color: #707070;
    max-width: 20%;
}

.datos-personales-column-big{
    -webkit-flex-grow: 1;
            flex-grow: 1;
    color: #707070;
    max-width: 40%;
}
.datos-personales-img-big{
    width: 400px;
    height: 250px;
    padding: 0 1em;
    object-fit: cover;
}

.datos-personales-img{
    width: 250px;
    height: 250px;
    padding: 0 1em;
    object-fit: cover;
}

.dato-personal-label{
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
}

.dato-personal{
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 14px;
}

.dato-personal-mid{
    margin: 0.5rem;
    font-size: 18px;
}

.dato-personal-big{
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 22px;
    color: #A7A7A7
}

.titulo-violeta{
    color: #9373B1;
    font-size: 22px;
    margin: 0;
    font-weight: bold;
}

.rectangulo-gris{
    border-radius: 10px;
    background-color:#F2F2F2;
    margin-bottom: 0.5rem;
    text-align: center;
    padding: 0.5rem;
}

.card-gris{
    background-color: #FAFAFA;
    padding: 1rem;
    width: 80%;
    margin: auto;
}

.card-title{
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin: 0;
    margin-bottom: 1.5rem;
}

.container-dato-biometrico{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.rectangulo-verde{
    background-color: #63D1A2;
    border-radius: 0px 0px 10px 10px;
    margin: auto;
    width: 80%;
    padding: 0 1em;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.rectangulo-rojo{
    background-color: #D44141;
    border-radius: 0px 0px 10px 10px;
    margin: auto;
    width: 80%;
    padding: 0 1em;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.rectangulo-gris-oscuro{
    background-color: #A7A7A7;
    border-radius: 0px 0px 10px 10px;
    margin: auto;
    width: 80%;
    padding: 0 1em;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.grid-datos-biometricos{
    height: 30vh;
    width: 100%
}

.metodos-de-pago{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-right: 1em;
    -webkit-flex-grow: 2;
            flex-grow: 2;
    max-width: 778px; 
}

.ultimos-movimientos{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-grow: 3;
            flex-grow: 3
}

.grid-suscripciones{
    height: 30vh;
    width: 100%;
    max-width: 1160px;
}
.Buttons_buttonsContainer___oVXq{
    height: 4em;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end
}

.Buttons_icons__kZXIZ{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between
}
.form__image-container {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.form__image-container .image-with-label {
    text-align: center;
    margin: 1rem 1rem 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.image-with-label img {
    margin: 0 0.5rem 1rem;
    height: 180px;
    max-height: 180px;
    max-width: 300px;
    cursor: pointer;
    zoom: 100%;
}
.image-with-label p {
    margin: 0;
}

.slider-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 1rem;
}
.slider-container label {
    margin-right: 1rem;
}

.form__button-container{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    margin-top: 20px
}
.form__button{
    display: -webkit-flex;
    display: flex;
    border-radius: 50%; 
    height: 50px;
    width: 50px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.endUserModal_container__fArTE{
    font-family: 'Roboto', sans-serif;
    width: 80%;
    margin: 0.5rem 4rem;
}

.endUserModal_title__b9hDE {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: #63d1a2;
    font-weight: lighter;
    font-size: 1.5rem;
}
.endUserModal_divider__3lxxg {
    width: 100%;
}
.endUserModal_result__KpnEG{    
    display: -webkit-flex;    
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

.endUserModal_status__1d5dT{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 80%;
}

.endUserModal_icon__1ooje{
    margin-right: 1em;
}

.endUserModal_registro__qwHuB{
    -webkit-flex-grow: 1;
            flex-grow: 1;
    margin: 0.75rem 0;
}

.endUserModal_checkIcon__BF1NJ{
    color: #63d1a2;
}
.endUserModal_updateIcon__1WlY_{
    width: 32px;
    height: 32px;
}
.endUserModal_cancelIcon__8fiq-{
    color: #f00
}

.endUserModal_editIcon__1fwZ6{
    height: 2em;
    cursor: pointer;
}

.endUserModal_closeButtonContainer__fmHpH {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.endUserModal_button__2ccSL{
    color: inherit;
    width: 274px;
    height: 40px;
    background: #ddd;
    border-radius: 21;
    opacity: 1;
    font-weight: 300;
}
.IdentityModal_container__28fjw{
    display: -webkit-flex;
    display: flex;
}

.IdentityModal_column__3gijG{
    width: 50%;
    position: relative;
    padding: 0.5rem
}

.IdentityModal_selfie___AJOX{
    width: 60%;
    max-height: 650px;
}

.IdentityModal_fotoDNI__PmEVq{
    position: absolute;
    width: 168px;
    height: 168px;
    right: 24px;
    cursor: pointer;
    z-index: 100;
}

.IdentityModal_dni__12Y52{
    position: absolute;
    width: 168px;
    height: 168px;
    right: 24px;
    top: 192px;
    cursor: pointer;
}

.IdentityModal_inputRow__e03jq {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.IdentityModal_buttonRow__fu8Qu{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.IdentityModal_button__3_8CU{
    width: 10rem;
    padding: 6px 16px!important;
    height: 40px!important;
    color: #ffffff!important;
    background: linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%);
    text-transform: capitalize!important;
    border-radius: 2rem!important;
    font-weight: 300!important;
    margin-bottom: 1rem!important;
}

.IdentityModal_button__3_8CU:hover{
    background: linear-gradient(90deg, #13d5a5 0%, #13c5d5 100%);
}

.IdentityModal_closeButton__2mEYB{
    background: #bbb!important;
}

.IdentityModal_closeButton__2mEYB:hover{
    background: #777!important;
}


.LocationModal_container__3lRSF {
    width: 100%;
}
.LocationModal_title__3bz6y {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: #63d1a2;
    font-weight: 300;
    font-size: 1.5rem;
}
.LocationModal_row__3_-Iv{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.LocationModal_column___BfY7 {
    width: 45%;
}

.LocationModal_inputBig__2FN2m{
    width: 30%
}

.LocationModal_inputSm__3mKOX{
    width: 15%
}

.LocationModal_buttons__2WcsL {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.LocationModal_buttonRow__1TUqn{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.LocationModal_button__2K8mh{
    width: 10rem;
    padding: 6px 16px!important;
    height: 40px!important;
    color: #ffffff!important;
    background: linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%)!important;
    text-transform: capitalize!important;
    border-radius: 2rem!important;
    font-weight: 300!important;
    margin: 0 1rem 1rem!important;
}

.LocationModal_button__2K8mh:hover{
    background: linear-gradient(90deg, #13d5a5 0%, #13c5d5 100%)!important;
}

.LocationModal_buttonCoordenadas__dikKR{
    position: absolute!important;
    width: 14rem;
    height: 2rem;
    color: #ffffff!important;
    background: linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%)!important;
    border-radius: 21px!important;
    text-transform: capitalize!important;
    font-weight: 300!important;
    bottom: 1.5rem;
    left: 1.5rem;  
}

.LocationModal_closeButton__2fpWS{
    background: #bbb!important;
}

.LocationModal_closeButton__2fpWS:hover{
    background: #777!important;
}

.LocationModal_mapa__3n-3b {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding-right: 4rem;
}

.LocationModal_mapaModal__3xiir {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column
}

.LocationModal_searchBoxContainer__5eZMP {
    margin-bottom: 1rem;
    padding-right: 2rem;
}

.LocationModal_searchBox__v9i2n {
    border: 1px solid #000;
    width: 100%;
    height: 32px;
    padding: 0 12px;
    border-radius: 3px;
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
    z-index: 100;
    color: #000;
}
.cuilcuit_container__1MaSj{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}
.cuilcuit_title__2wRej {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: #63d1a2;
    font-weight: 300;
    font-size: 1.5rem;
}
.cuilcuit_label__Igx1m{
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    margin-bottom: 8px;
}

.cuilcuit_buttonRow__bP_4B{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.cuilcuit_input__1AvKA{
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #000;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    -webkit-animation-name: cuilcuit_mui-auto-fill-cancel__3R5YA;
            animation-name: cuilcuit_mui-auto-fill-cancel__3R5YA;
    letter-spacing: inherit;
    -webkit-animation-duration: 10ms;
            animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    margin-bottom: 16px;
}

.cuilcuit_input__1AvKA:focus{
    outline: 0;
}

.cuilcuit_button__1QInF{
    width: 10rem;
    padding: 6px 16px!important;
    height: 40px!important;
    color: #ffffff!important;
    background: linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%)!important;
    text-transform: capitalize!important;
    border-radius: 2rem!important;
    font-weight: 300!important;
    margin: 0 1rem 1rem!important;
}

.cuilcuit_button__1QInF:hover{
    background: linear-gradient(90deg, #13d5a5 0%, #13c5d5 100%)!important;
}

.cuilcuit_closeButton__FbB-z{
    background: #bbb!important;
}

.cuilcuit_closeButton__FbB-z:hover{
    background: #777!important;
}
.ModalInfoMerchant_container__3dy06 {
    width: 100%;
    max-height: 85vh;
    overflow-y: auto;
}
.ModalInfoMerchant_title__36u7a {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: #63d1a2;
    font-weight: lighter;
    font-size: 1.5rem;
    font-family: var(--font-g);
}

.ModalInfoMerchant_grid__1hvIL {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    text-align: left;
    padding-left: 20px;
}

.ModalInfoMerchant_cell__38nf5 {
    padding: 8px;
}

.ModalInfoMerchant_subtitle__8dc7c {
    font-weight: bold;
}

.ModalInfoMerchant_info__622Pr {
    font-weight: 400;
}

.ModalInfoMerchant_imageContainer__3UoLT {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin: 20px 0;
    width: 100%;
}

.ModalInfoMerchant_image__bshDU {
    max-width: 400px;
}

.ModalInfoMerchant_closeButtonContainer__1G64C {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.ModalInfoMerchant_button__23bd4 {
    color: #ffffff!important;
    width: 16rem!important;
    height: 2rem!important;
    background: linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%)!important;
    border-radius: 21px!important;
    opacity: 1!important;
    text-transform: none!important;
    font-weight: 300!important;
    font-family: 'Roboto', sans-serif!important;
    margin-top: 1em!important;
    border: none;
}
.PreonboardingManual_container__2fe1j {
    display: -webkit-flex;
    display: flex;
    margin-top: 1rem;
}

.PreonboardingManual_column__2IwmI {
    width: 50%;
    position: relative;
    padding: 0.5rem;
}

.PreonboardingManual_imageRow__2sxmi {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 220px;
    text-align: center;
    padding: 1rem;
}

.PreonboardingManual_imageRow__2sxmi .PreonboardingManual_rotar__hmdqo {
    max-height: 42px;
    max-width: 42px;
    aspect-ratio: 1/1;
    background: transparent;
}

.PreonboardingManual_imageContainer__3nMG6 {
    width: 45%;
    cursor: pointer;
    margin: 0.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    max-height: 220px;
}
.PreonboardingManual_imageContainer__3nMG6 img {
    max-width: 100%;
    max-height: 220px;
    width: -webkit-max-content;
    width: max-content;
    border-radius: 5px;
    margin: 0.5rem;
}

.PreonboardingManual_inputRow__1g8RE {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.PreonboardingManual_buttonRow__uLwFz {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.PreonboardingManual_modalRecorte__9goDx {
    max-height: 80vh;
}

.PreonboardingManual_cropperContainer__gPw0C {
    display: -webkit-flex;
    display: flex;
    width: 80%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    max-height: 60vh;
    -webkit-align-items: center;
            align-items: center;
}

.PreonboardingManual_cropper__1Mwwf {
    max-width: 400px;
    max-height: 60vh;
    margin: 1rem;
}

.PreonboardingManual_closeButtonContainer__1ORYj {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.PreonboardingManual_button__J7uQe {
    width: 10rem;
    padding: 6px 16px !important;
    height: 40px !important;
    color: #ffffff !important;
    background: linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%);
    border-radius: 2rem !important;
    font-weight: 300 !important;
    margin-bottom: 1rem !important;
    text-transform: capitalize !important;
}

.PreonboardingManual_closeButton__1PhvX {
    background: #bbb !important;
    width: 10rem;
    padding: 6px 16px !important;
    height: 40px !important;
    color: #ffffff !important;
    border-radius: 2rem !important;
    font-weight: 300 !important;
    margin: 0 1rem 1rem !important;
}

.PreonboardingManual_closeButton__1PhvX:hover {
    background: #777 !important;
}

.PreonboardingManual_title__1vK6G {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    color: #63d1a2;
    font-weight: lighter;
    font-size: 1.5rem;
}
* {
  font-family: "Roboto", sans-serif;
}

body {
    margin: 0;
    box-sizing: border-box;
    overflow-y: hidden;
    text-decoration: none!important;
}  

label {
    font-weight: bold!important;
}

.mainContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
}

.figures {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  padding-top: 8rem;
}

.figures .elipse {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
}

.elipse img {
  width: 7rem;
  padding-left: 1.5rem;
}

.elipse .rombo {
  width: 0.7rem;
  padding-top: 1rem;
  margin-left: 3rem;
}

.figures .vertical-line {
  padding-top: 6.4rem;
  padding-right: 13%;
  margin-left: -3.4rem;
}

.vertical-line .line {
  width: 3rem;
}

.section {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  justify-content: center;
  line-height: 0;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  width: 100%;
}

.section h1 {
  color: #63C3D1;
  font-family: var(--font-g);
  font-weight: 300;
  font-size: 2rem;
}

.section p {
  color: #707070;
  font-family: var(--font-g);
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  text-align: center;
  width: 30%;
}

.figuresLeft {
  display: flex;
  align-items: center;
  height: auto;
  justify-content: flex-start;
  width: 100%;
}

.containerLeft {
  display: flex;
  padding-left: 13%;
  padding-top: 2%;
}

.halfWheel img {
  width: 7rem;
}

.cross {
  margin-left: -16%;
}

.cross img {
  width: 1.8rem;
}

.rombo {
  padding-left: 12%;
  padding-top: 19%;
}

.rombo img {
  width: 0.82rem;
}

@media (min-width: 1920px) {
  .figures {
    top: 14rem;
  }

  .elipse img {
    width: 10.81rem;
  }

  .elipse .rombo {
    width: 1.51rem;
  }

  .figures .vertical-line {
    padding-top: 10rem;
    margin-left: -4%;
    padding-right: 9%;
  }

  .vertical-line .line {
    width: 4.61rem;
  }

  .section h1 {
    font-size: 2.5rem;
  }

  .section p {
    font-weight: 300 lighter;
    font-size: 1.4rem;
    width: 35%;
  }

  .half-wheel img {
    width: 10.83rem;
  }

  .cross img {
    width: 2.12rem;
  }

  .rombo {
    margin-top: 5%;
  }

  .rombo img {
    width: 1.09rem;
  }
  .container-left {
    padding-left: 9%;
  }

  .cross {
    margin-top: 5%;
    margin-left: -21%;
  }
}

@media (max-width: 1600px) {
  .figures {
    margin-top: -10%;
  }
}

@media (max-width: 1280px) {
  .figures {
    margin-top: -7%;
  }
}

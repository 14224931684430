.form__image-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.form__image-container .image-with-label {
    text-align: center;
    margin: 1rem 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.image-with-label img {
    margin: 0 0.5rem 1rem;
    height: 180px;
    max-height: 180px;
    max-width: 300px;
    cursor: pointer;
    zoom: 100%;
}
.image-with-label p {
    margin: 0;
}

.slider-container {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}
.slider-container label {
    margin-right: 1rem;
}

.form__button-container{
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px
}
.form__button{
    display: flex;
    border-radius: 50%; 
    height: 50px;
    width: 50px;
    justify-content: center;
    align-items: center;
}
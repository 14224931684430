@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

* {
  font-family: "Roboto", sans-serif;
}

body {
    margin: 0;
    box-sizing: border-box;
    overflow-y: hidden;
    text-decoration: none!important;
}  

label {
    font-weight: bold!important;
}